// @ts-nocheck
import ERC20_INTERFACE from '../../constants/abis/erc20'
import { useMultipleContractSingleData } from '../../state/multicall/hooks'
import {utils} from 'ethers'
import {useContract} from '../../hooks/useContract'
import {useState, useEffect} from 'react'
import distributorApi from '../abis/distributor'
import {distributorAddress} from '../xplebBuySettings'

export function useBuyXpleb() {
  const contract = useContract(distributorAddress, distributorApi)
  return contract?.buy
}
