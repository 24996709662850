// @ts-nocheck
import React from 'react'
import { useMinichefPools, useMinichefStakingInfos } from '../../state/stake/hooks'
import Earn from './Lock'
import { Token, Pair, TokenAmount } from '@pangolindex/sdk'
import {useStakingInfo} from '../../plebbit/hooks/nftLock'
import {xplebPlebLockerAddress, xplebLpLockerAddress} from '../../plebbit/settings'
import nftLockSettings from '../../plebbit/nftLockSettings'

const stakingInfo = {
  "stakingRewardAddress": "0x1f806f7C8dED893fd3caE279191ad7Aa3798E928",
  "tokens": [
    new Token(43114, "0x60781C2586D68229fde47564546784ab3fACA982", 18, "PNG","Pangolin"),
    new Token(43114, "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7", 18, "WAVAX","Wrapped AVAX")
  ],
  "earnedAmount": new TokenAmount(
    new Token(43114, "0x60781C2586D68229fde47564546784ab3fACA982", 18, "PNG","Pangolin"), 
    '1000' + '1000000000000000000'
  ),
  "rewardRate": new TokenAmount(
    new Token(43114, "0x60781C2586D68229fde47564546784ab3fACA982", 18, "PNG","Pangolin"), 
    '1000' + '1000000000000000000'
  ),
  "totalRewardRate": new TokenAmount(
    new Token(43114, "0x60781C2586D68229fde47564546784ab3fACA982", 18, "PNG","Pangolin"), 
    '1000' + '1000000000000000000'
  ),
  "stakedAmount": new TokenAmount(
    new Token(43114, "0xd7538cABBf8605BdE1f4901B47B8D42c61DE0367", 18, "AXP","avaXwap"), 
    '1000' + '1000000000000000000'
  ),
  "totalStakedAmount": new TokenAmount(
    new Token(43114, "0xd7538cABBf8605BdE1f4901B47B8D42c61DE0367", 18, "AXP","avaXwap"), 
    '1000' + '1000000000000000000'
  ),
  "totalStakedInWavax": new TokenAmount(
    new Token(43114, "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7", 18, "WAVAX","Wrapped AVAX"), 
    '1000' + '1000000000000000000'
  ),
  "totalStakedInUsd": new TokenAmount(
    new Token(43114, "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664", 6, "USDC.e","USD Coin"), 
    '1000' + '1000000000000000000'
  ),
  "multiplier": [
    100
  ],
  "periodFinish": "2222-02-22T10:00:23.000Z",
  "isPeriodFinished": false
}

const poolMap = new Map([['0x0B1efd689eBA7E610955d0FaBd9Ab713a04c3895','33',],
['0x0CE543c0f81ac9AAa665cCaAe5EeC70861a6b559','37',],
['0x0EaeeC3Ae72E183Dd701dB6F50077945E0809CDD','82',],
['0x0a63179a8838b5729E79D239940d7e29e40A0116','29',],
['0x0e0100Ab771E9288e0Aa97e11557E6654C3a9665','55',],
['0x1bFae75b28925BF4a5bf830c141EA29CB0A868F1','65',],
['0x2F151656065E1d1bE83BD5b6F5e7509b59e6512D','4',],
['0x2b532bC0aFAe65dA57eccFB14ff46d16a12de5E6','64',],
['0x3c0ECf5F430bbE6B16A8911CB25d898Ef20805cF','75',],
['0x3e58e8D1c403c353f6cFe8b9582bB17bD7f433f3','81',],
['0x04D80d453033450703E3DC2d0C1e0C0281c42D81','30',],
['0x4A045a80967B5ecc440c88dF9a15a3339d43D029','66',],
['0x4E9A38F05c38106C1cf5c145Df24959ec50ff70D','90',],
['0x4F20E367B10674cB45Eb7ede68c33B702E1Be655','27',],
['0x4a2cB99e8d91f82Cf10Fb97D43745A1f23e47caA','17',],
['0x4ec2d6d37Bc8F47fdc995E0F311CeB60D9b12f51','71',],
['0x6CbfB991986EbbBc91Bf21CeaA3cBf1BD82469cf','44',],
['0x7c05d54fc5CB6e4Ad87c6f5db3b807C94bB89c52','8',],
['0x7e6BC3A57fBcbAb72b0CbB4B2eCBAdD26373593F','88',],
['0x8a9c36BC3CEd5ECce703A4dA8032218Dfe72fE86','54',],
['0x8dEd946a4B891D81A8C662e07D49E4dAee7Ab7d3','16',],
['0x9AA76aE9f804E7a70bA3Fb8395D0042079238E9C','46',],
['0x11cb8967c9CEBC2bC8349ad612301DaC843669ea','43',],
['0x32c1f9C11AEe9b1175E7D8ca61656F253690B795','86',],
['0x40e747f27E6398b1f7C017c5ff5c31a2Ab69261c','76',],
['0x62a2F206CC78BAbAC9Db4dbC0c9923D4FdDef047','61',],
['0x66EeCC97203704d9E2Db4A431cb0e9cE92539D5a','62',],
['0x73e6CB72a79dEa7ed75EF5eD6f8cFf86C9128eF5','45',],
['0x134Ad631337E8Bf7E01bA641fB650070a2e0efa8','14',],
['0x221Caccd55F16B5176e14C0e9DBaF9C6807c83c9','11',],
['0x239aAE4AaBB5D60941D7DFFAeaFE8e063C63Ab25','52',],
['0x424d2787213F3B1646f9d320f33D0b5148df80f5','47',],
['0x494Dd9f783dAF777D3fb4303da4de795953592d0','23',],
['0x902c65C90327285BB1d8BdC07B59Cd199410A71b','68',],
['0x911aE99620f4c42038FC4D1B6Da6F5135Bee8356','70',],
['0x960FA242468746C59BC32513E2E1e1c24FDFaF3F','40',],
['0x997B92C4c9d3023C11A937eC322063D952337236','49',],
['0x5764b8D8039C6E32f1e5d8DE8Da05DdF974EF5D3','5',],
['0x5861D23Ca326e9BEff23D4C5E906b8CbbF251CBD','60',],
['0x5875c368Cddd5FB9Bf2f410666ca5aad236DAbD4','10',],
['0x5944f135e4F1E3fA2E5550d4B5170783868cc4fE','77',],
['0x6745d7F9289d7d75B5121876B1b9D8DA775c9a3E','12',],
['0x42152bDD72dE8d6767FE3B4E17a221D6985E8B25','18',],
['0x64694FC8dFCA286bF1A15b0903FAC98217dC3AD7','89',],
['0x497070e8b6C55fD283D8B259a6971261E2021C01','28',],
['0x662135c6745D45392bf011018f95Ad9913DcBf5c','36',],
['0x792055e49a6421F7544c5479eCC380bad62Bc7EE','80',],
['0x7472887De3B0aA65168a1Da22164C81DE5fd4044','85',],
['0x45324950c6ba08112EbF72754004a66a0a2b7721','83',],
['0x4555328746f1b6a9b03dE964C90eCd99d75bFFbc','13',],
['0x45430378272aed047cD33b688aDa004a06f435C0','69',],
['0x5085678755446F839B1B575cB3d1b6bA85C65760','32',],
['0xA34862a7de51a0E1aEE6d3912c3767594390586d','34',],
['0xB6eE4A02F1fa523559B9Abb54C50dF3011911fe9','79',],
['0xBAe8Ee2D95Aa5c68Fe8373Cd0208227E94075D5d','87',],
['0xC33Ac18900b2f63DFb60B554B1F53Cd5b474d4cd','1',],
['0xC58cC1a0f29f1993D089681e4fA03c7f65dF1325','57',],
['0xC132ff3813De33356C859979501fB212673e395e','56',],
['0xC251d8C511aa5418F1edd57b5A565876Be747Dfb','48',],
['0xD4CBC976E1a1A2bf6F4FeA86DEB3308d68638211','3',],
['0xE9DfCABaCA5E45C0F3C151f97900511f3E73Fb47','25',],
['0xE44Ef634A6Eca909eCb0c73cb371140DE85357F9','24',],
['0xE64CC27ed4A54ac4FdeE31eFc56D363AF2Ee5A13','63',],
['0xE75eD6E50e3e2dc6b06FAf38b943560BD22e343B','38',],
['0xE530dC2095Ef5653205CF5ea79F8979a7028065c','19',],
['0xEd764838FA66993892fa37D57d4036032B534f24','35',],
['0xF52B3Df311182F43202806ee0E72aCB92d777879','50',],
['0xF776Ef63c2E7A81d03e2c67673fd5dcf53231A3f','78',],
['0xFbA30990f79E1dF3842bB8aC3d86b2454A760151','59',],
['0xa1C2c3B6b120cBd4Cec7D2371FFd4a931A134A32','22',],
['0xa7A175A26bbCB1eacA6FF5318022e27e1DF46fce','58',],
['0xb57c80a860e510e15D4ce01af944E65ccF9cD673','73',],
['0xb68F4e8261A4276336698f5b11DC46396cf07A22','39',],
['0xbA09679Ab223C6bdaf44D45Ba2d7279959289AB0','6',],
['0xbCEd3B6D759B9CA8Fc7706E46Aa81627b2e9EAE8','53',],
['0xbb700450811a30c5ee0dB80925Cf1BA53dBBd60A','42',],
['0xbd918Ed441767fe7924e99F6a0E0B568ac1970D9','9',],
['0xc8F980957a7a93b62C1D02D253E661C6d412B244','72',],
['0xc13E562d92F7527c4389Cd29C67DaBb0667863eA','2',],
['0xd2F01cd87A43962fD93C21e07c1a420714Cc94C9','15',],
['0xd05e435Ae8D33faE82E8A9E79b28aaFFb54c1751','26',],
['0xd69De4d5FF6778b59Ff504d7d09327B73344Ff10','31',],
['0xd7538cABBf8605BdE1f4901B47B8D42c61DE0367','0',],
['0xdeaBb6e80141F5E557EcBDD7e9580F37D7BBc371','74',],
['0xe9E5A27314f1b87ea6484139B98Eaf816c6688a4','41',],
['0xe36AE366692AcBf696715b6bDDCe0938398Dd991','21',],
['0xe8579e213E85d52f6559CB0070eA6c912718b4f4','67',],
['0xe28984e1EE8D431346D32BeC9Ec800Efb643eef4','7',],
['0xeB69651B7146F4A42EBC32B03785C3eEddE58Ee7','84',],
['0xf7FF4fb01c3c1Ab0128A79953CD8B47526292FB2','51',],
['0xf0252ffAF3D3c7b3283E0afF56B66Db7105c318C','20',]])

interface Props {}
const EarnV2: React.FC<Props> = () => {
  // const stakingInfos = useMinichefStakingInfos(2)
  // const poolMap = useMinichefPools()

  const stakingInfo1 = useStakingInfo(nftLockSettings[0].lockerAddress, nftLockSettings[0].lockTokenAddress, nftLockSettings[0].lockIndex)
  const stakingInfo2 = useStakingInfo(nftLockSettings[1].lockerAddress, nftLockSettings[1].lockTokenAddress, nftLockSettings[1].lockIndex)
  const stakingInfo3 = useStakingInfo(nftLockSettings[2].lockerAddress, nftLockSettings[2].lockTokenAddress, nftLockSettings[2].lockIndex)
  const stakingInfo4 = useStakingInfo(nftLockSettings[3].lockerAddress, nftLockSettings[3].lockTokenAddress, nftLockSettings[3].lockIndex)
  const stakingInfo5 = useStakingInfo(nftLockSettings[4].lockerAddress, nftLockSettings[4].lockTokenAddress, nftLockSettings[4].lockIndex)
  const stakingInfo6 = useStakingInfo(nftLockSettings[5].lockerAddress, nftLockSettings[5].lockTokenAddress, nftLockSettings[5].lockIndex)

  const stakingInfos = []
  if (stakingInfo) {
    stakingInfos.push({...stakingInfo1, ...nftLockSettings[0]})
    stakingInfos.push({...stakingInfo2, ...nftLockSettings[1]})
    stakingInfos.push({...stakingInfo3, ...nftLockSettings[2]})
    stakingInfos.push({...stakingInfo4, ...nftLockSettings[3]})
    stakingInfos.push({...stakingInfo5, ...nftLockSettings[4]})
    stakingInfos.push({...stakingInfo6, ...nftLockSettings[5]})
  }
  // console.log(stakingInfos)

  return <Earn version="2" stakingInfos={stakingInfos} poolMap={poolMap} />
}

export default EarnV2
