// @ts-nocheck
import React from 'react'
import { useMinichefStakingInfos } from '../../state/stake/hooks'
import { RouteComponentProps } from 'react-router-dom'
import Manage from './LockManage'
import { usePair } from '../../data/Reserves'
import { wrappedCurrency } from '../../utils/wrappedCurrency'
import { useCurrency } from '../../hooks/Tokens'
import { useActiveWeb3React } from '../../hooks'
import {useStakingInfo, usePlebToken, useWavaxToken} from '../../plebbit/hooks/nftLock'
import nftLockSettings from '../../plebbit/nftLockSettings'

const ManageV2: React.FC<RouteComponentProps<{ currencyIdA: string; currencyIdB: string; }>> = ({
  match: {
    params: { nftTicker, token, amount }
  }
}) => {
  // const { chainId } = useActiveWeb3React()
  // get currencies and pair
  // const [currencyA, currencyB] = [useCurrency(currencyIdA), useCurrency(currencyIdB)]
  // const tokenA = wrappedCurrency(currencyA ?? undefined, chainId)
  // const tokenB = wrappedCurrency(currencyB ?? undefined, chainId)

  // const [, stakingTokenPair] = usePair(tokenA, tokenB)
  // const miniChefStaking = useMinichefStakingInfos(2, stakingTokenPair)?.[0]

  let info
  for (const settings of nftLockSettings) {
    if (settings.lockTokenTicker === token && settings.nftTicker === nftTicker && settings.lockAmount === amount) {
      info = settings
    }
  }

  const miniChefStaking = useStakingInfo(info.lockerAddress, info.lockTokenAddress, info.lockIndex) // useMinichefStakingInfos(2, stakingTokenPair)?.[0]
  const currencyA = usePlebToken()
  const currencyB = useWavaxToken()
  currencyB.symbol = 'AVAX'

  const stakingInfo = {...miniChefStaking, ...info}

  // console.log({stakingInfo})

  return <Manage version="2" stakingInfo={stakingInfo} currencyA={currencyA} currencyB={currencyB} />
}

export default ManageV2
