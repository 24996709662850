// @ts-nocheck
import ERC20_INTERFACE from '../../constants/abis/erc20'
import { useMultipleContractSingleData } from '../../state/multicall/hooks'
import {utils} from 'ethers'
import {MerkleTree} from 'merkletreejs'
import keccak256 from 'keccak256'
import {useContract} from '../../hooks/useContract'
import {useState, useEffect} from 'react'
import {plebTokenAddress, farmAddress, lpTokenAddress, wavaxTokenAddress, usdcTokenAddress, chainId, usdcWavaxPairAddress} from '../settings'
import {ethers} from 'ethers'
import farmAbi from '../abis/farm.json'
import {useBlockNumber} from '../../state/application/hooks'
import {Token, TokenAmount} from '@pangolindex/sdk'
import {useActiveWeb3React} from '../../hooks'
import JSBI from 'jsbi'

export function useTotalStakedInWavax() {
  const tokenAmountWholeLP = useTotalLpBalanceInFarm()
  const farmLpBalance = useLpBalance(farmAddress)
  const lpTotalSupply =  useTotalSupply(lpTokenAddress)
  const wavaxBalanceInLp = useWavaxBalance(lpTokenAddress)
  const [totalStakedInAvax, setTotalStakedInAvax] = useState()
  // console.log({
  //   totalStakedInAvax: totalStakedInAvax && totalStakedInAvax.toString() / 1000000000000000000, 
  //   tokenAmountWholeLP: tokenAmountWholeLP && tokenAmountWholeLP.toString()  / 1000000000000000000, 
  //   farmLpBalance: farmLpBalance && farmLpBalance.toString()  / 1000000000000000000, 
  //   lpTotalSupply: lpTotalSupply && lpTotalSupply.toString()  / 1000000000000000000, 
  //   wavaxBalanceInLp: wavaxBalanceInLp && wavaxBalanceInLp.toString()  / 1000000000000000000
  // })

  useEffect(() => {
    if (tokenAmountWholeLP && farmLpBalance && lpTotalSupply && wavaxBalanceInLp) {
      const percentOfLpOwnedByFarm = farmLpBalance.mul(ethers.BigNumber.from('1000000000000')).div(lpTotalSupply)
      const _totalStakedInAvax = wavaxBalanceInLp.mul(percentOfLpOwnedByFarm).mul(ethers.BigNumber.from('2')).div(ethers.BigNumber.from('1000000000000'))
      setTotalStakedInAvax(_totalStakedInAvax)
    }
  }, [tokenAmountWholeLP, farmLpBalance, lpTotalSupply, wavaxBalanceInLp])

  return totalStakedInAvax
}

export function useTotalStakedInPleb() {
  const tokenAmountWholeLP = useTotalLpBalanceInFarm()
  const farmLpBalance = useLpBalance(farmAddress)
  const lpTotalSupply =  useTotalSupply(lpTokenAddress)
  const plebBalanceInLp = usePlebBalance(lpTokenAddress)
  const [totalStakedInPleb, setTotalStakedInPleb] = useState()

  useEffect(() => {
    if (tokenAmountWholeLP && farmLpBalance && lpTotalSupply && plebBalanceInLp) {
      const percentOfLpOwnedByFarm = farmLpBalance.mul(ethers.BigNumber.from('1000000000000')).div(lpTotalSupply)
      const _totalStakedInPleb = plebBalanceInLp.mul(percentOfLpOwnedByFarm).mul(ethers.BigNumber.from('2')).div(ethers.BigNumber.from('1000000000000'))
      setTotalStakedInPleb(_totalStakedInPleb)
    }
  }, [tokenAmountWholeLP, farmLpBalance, lpTotalSupply, plebBalanceInLp])

  return totalStakedInPleb
}

export function usePlebApr() {
  const totalStakedInPleb = useTotalStakedInPleb()
  const timeAdjustedTokenPerBlock = useTimeAdjustedTokenPerBlock()
  const [plebApr, setPlebApr] = useState()
  // console.log({
  //   totalStakedInPleb: totalStakedInPleb && totalStakedInPleb.toString(),
  //   timeAdjustedTokenPerBlock: timeAdjustedTokenPerBlock && timeAdjustedTokenPerBlock.toString(),
  // })

  useEffect(() => {
    if (totalStakedInPleb && timeAdjustedTokenPerBlock) {
      if (totalStakedInPleb.toString() === '0') {
        return
      }
      const totalPlebPerYear = timeAdjustedTokenPerBlock.mul(ethers.BigNumber.from(365 * 42200))
      const apr = totalPlebPerYear.mul(ethers.BigNumber.from('1000000000000')).div(totalStakedInPleb)
      // console.log({
      //   totalPlebPerYear: totalPlebPerYear && totalPlebPerYear.toString(),
      //   apr: apr && apr.toString(),
      // })
      // console.log(apr)
      // console.log((apr.toString() / 1000000000000) * 100)
      setPlebApr((apr.toString() / 1000000000000) * 100)
    }
  }, [totalStakedInPleb, timeAdjustedTokenPerBlock])

  return plebApr
}

export function useSwapApr() {
  const abi = [{"anonymous": false, "inputs": [{"indexed": true, "internalType": "address", "name": "sender", "type": "address"}, {"indexed": false, "internalType": "uint256", "name": "amount0In", "type": "uint256"}, {"indexed": false, "internalType": "uint256", "name": "amount1In", "type": "uint256"}, {"indexed": false, "internalType": "uint256", "name": "amount0Out", "type": "uint256"}, {"indexed": false, "internalType": "uint256", "name": "amount1Out", "type": "uint256"}, {"indexed": true, "internalType": "address", "name": "to", "type": "address"} ], "name": "Swap", "type": "event"}]
  const contract = useContract(lpTokenAddress, abi)
  const blockNumber = useBlockNumber()
  const [swapApr, setSwapApr] = useState()
  const totalStakedInPleb = useTotalStakedInPleb()
  const wavaxBalanceInLp = useWavaxBalance(lpTokenAddress)
  const plebBalanceInLp = usePlebBalance(lpTokenAddress)

  useEffect(() => {
    if (contract && blockNumber && totalStakedInPleb && wavaxBalanceInLp && plebBalanceInLp) {
      if (totalStakedInPleb.toString() === '0') {
        return
      }
      if (wavaxBalanceInLp.toString() === '0') {
        return
      }
      if (swapApr !== undefined) {
        return
      }
      ;(async () => {
        const filter = contract.filters.Swap()
        const events = await contract.queryFilter(filter, blockNumber - 42200, blockNumber)
        let plebVolume = ethers.BigNumber.from('0')
        let avaxVolume = ethers.BigNumber.from('0')
        for (const event of events) {
          plebVolume = plebVolume.add(event.args.amount0In)
          avaxVolume = avaxVolume.add(event.args.amount1In)
        }
        const fee = ethers.BigNumber.from('3') // 0.3%
        const plebFees = plebVolume.div(ethers.BigNumber.from('1000')).div(fee)
        const avaxFees = avaxVolume.div(ethers.BigNumber.from('1000')).div(fee)
        const plebWavaxRatio = plebBalanceInLp.div(wavaxBalanceInLp)
        const avaxFeesInPleb = avaxFees.mul(plebWavaxRatio)
        const totalFeesInPleb = plebFees.add(avaxFeesInPleb)
        const apr = totalFeesInPleb.mul(ethers.BigNumber.from('1000000000000')).div(totalStakedInPleb)
        // console.log(apr)
        // console.log((apr.toString() / 1000000000000) * 100)
        setSwapApr((apr.toString() / 1000000000000) * 100)
      })()

    }
  }, [contract, blockNumber, totalStakedInPleb, wavaxBalanceInLp, plebBalanceInLp])

  return swapApr
}

export function useAprs() {
  const plebApr = usePlebApr()
  const swapApr = useSwapApr()
  return {plebApr, swapApr}
}

export function useAvaxPrice() {
  const pairAvaxBalance = useWavaxBalance(usdcWavaxPairAddress)
  const pairUsdcBalance = useUsdcBalance(usdcWavaxPairAddress)
  const [price, setPrice] = useState()
  // console.log({price: price && price.toString(), pairAvaxBalance, pairUsdcBalance})

  useEffect(() => {
    ;(async () => {
      if (!pairAvaxBalance || !pairUsdcBalance) {
        return
      }
      if (pairAvaxBalance.toString() === '0') {
        return
      }
      let _pairUsdcBalance = pairUsdcBalance.mul(ethers.BigNumber.from('1000000000000'))
      setPrice(_pairUsdcBalance.div(pairAvaxBalance))
    })()
  }, [pairAvaxBalance, pairUsdcBalance])

  return price
}

export const useFarmContract = () => {
  const contract = useContract(farmAddress, farmAbi)
  return contract
}

export function useUserLiquidityUnstaked(address) {
  const lpBalance = useLpBalance(address)
  if (lpBalance) {
    return new TokenAmount(
      new Token(chainId, lpTokenAddress, 18, "PLEB-AVAX JLP","JLP"), 
      lpBalance.toString()
    )
  } 
}

export function usePlebToken() {
  return new Token(chainId, plebTokenAddress, 18, "PLEB","Plebbit")
}

export function useWavaxToken() {
  return new Token(chainId, wavaxTokenAddress, 18, "WAVAX","Wrapped AVAX")
}

export function useStakingInfo() {
  const defaultStakingInfo = {
  "stakingRewardAddress": farmAddress,
  "tokens": [
    new Token(chainId, plebTokenAddress, 18, "PLEB","Plebbit"),
    new Token(chainId, wavaxTokenAddress, 18, "WAVAX","Wrapped AVAX")
  ],
  "multiplier": [100],
  "periodFinish": "2222-02-22T10:00:23.000Z",
  "isPeriodFinished": false
}
  const [stakingInfo, setStakingInfo] = useState(defaultStakingInfo)
  const {account} = useActiveWeb3React()
  const pendingToken = usePendingToken(account)
  const timeAdjustedTokenPerBlock = useTimeAdjustedTokenPerBlock()
  const depositedAmount = useDepositedAmount(account)
  const totalLpBalanceInFarm = useTotalLpBalanceInFarm()
  const totalStakedInWavax = useTotalStakedInWavax()
  const avaxPrice = useAvaxPrice()
  // console.log({
  //   pendingToken: pendingToken && pendingToken.toString(),
  //   timeAdjustedTokenPerBlock: timeAdjustedTokenPerBlock && timeAdjustedTokenPerBlock.toString(),
  //   depositedAmount: depositedAmount && depositedAmount.toString(),
  //   totalLpBalanceInFarm: totalLpBalanceInFarm && totalLpBalanceInFarm.toString(),
  //   totalStakedInWavax: totalStakedInWavax && totalStakedInWavax.toString(),
  //   avaxPrice: avaxPrice && avaxPrice.toString()
  // })

  useEffect(() => {
    ;(async () => {
      if (!pendingToken || !timeAdjustedTokenPerBlock || !depositedAmount || !totalLpBalanceInFarm) {
        if (totalStakedInWavax && totalLpBalanceInFarm && timeAdjustedTokenPerBlock) {
          let totalStakedInUsdc
          if (totalStakedInWavax && avaxPrice) {
            // mul x 100000 because is usdc with 6 decimals
            totalStakedInUsdc = totalStakedInWavax.mul(avaxPrice).mul(ethers.BigNumber.from('1000000')).div(ethers.BigNumber.from('1000000000000000000'))
          }
          setStakingInfo({
            ...defaultStakingInfo,
            "totalRewardRate": new TokenAmount(
              new Token(chainId, plebTokenAddress, 18, "PLEB","Plebbit"), 
              timeAdjustedTokenPerBlock.toString()
            ),
            "totalStakedAmount": new TokenAmount(
              new Token(chainId, lpTokenAddress, 18, "PLEB-AVAX JLP","JLP"), 
              totalLpBalanceInFarm.toString()
            ),
            "totalStakedInWavax": new TokenAmount(
              new Token(chainId, wavaxTokenAddress, 18, "WAVAX","Wrapped AVAX"), 
              totalStakedInWavax ? totalStakedInWavax.toString() : '0'
            ),
            "totalStakedInUsd": totalStakedInUsdc && new TokenAmount(
              new Token(chainId, usdcTokenAddress, 6, "USDC","USD Coin"), 
              totalStakedInUsdc ? totalStakedInUsdc.toString() : '0'
            )
          })
        }
        return
      }
      let percentOfPool
      if (totalLpBalanceInFarm.toString() === '0') {
        percentOfPool = ethers.BigNumber.from('0')
      }
      else {
        percentOfPool = depositedAmount.mul(ethers.BigNumber.from('1000000000000')).div(totalLpBalanceInFarm)
      }
      const ownRewardRate = timeAdjustedTokenPerBlock.mul(percentOfPool).div('1000000000000')

      let totalStakedInUsdc
      if (totalStakedInWavax && avaxPrice) {
        // mul x 100000 because is usdc with 6 decimals
        totalStakedInUsdc = totalStakedInWavax.mul(avaxPrice).mul(ethers.BigNumber.from('1000000')).div(ethers.BigNumber.from('1000000000000000000'))
      }
      // console.log({totalStakedInUsdc})

      const stakingInfo = {
        "stakingRewardAddress": farmAddress,
        "tokens": [
          new Token(chainId, plebTokenAddress, 18, "PLEB","Plebbit"),
          new Token(chainId, wavaxTokenAddress, 18, "WAVAX","Wrapped AVAX")
        ],
        "earnedAmount": new TokenAmount(
          new Token(chainId, plebTokenAddress, 18, "PLEB","Plebbit"), 
          pendingToken.toString()
        ),
        "rewardRate": new TokenAmount(
          new Token(chainId, plebTokenAddress, 18, "PLEB","Plebbit"), 
          ownRewardRate.toString()
        ),
        "totalRewardRate": new TokenAmount(
          new Token(chainId, plebTokenAddress, 18, "PLEB","Plebbit"), 
          timeAdjustedTokenPerBlock.toString()
        ),
        "stakedAmount": new TokenAmount(
          new Token(chainId, lpTokenAddress, 18, "PLEB-AVAX JLP","JLP"), 
          depositedAmount.toString()
        ),
        "totalStakedAmount": new TokenAmount(
          new Token(chainId, lpTokenAddress, 18, "PLEB-AVAX JLP","JLP"), 
          totalLpBalanceInFarm.toString()
        ),
        "totalStakedInWavax": new TokenAmount(
          new Token(chainId, wavaxTokenAddress, 18, "WAVAX","Wrapped AVAX"), 
          totalStakedInWavax ? totalStakedInWavax.toString() : '0'
        ),
        "totalStakedInUsd": totalStakedInUsdc && new TokenAmount(
          new Token(chainId, usdcTokenAddress, 6, "USDC","USD Coin"), 
          totalStakedInUsdc ? totalStakedInUsdc.toString() : '0'
        ),
        "multiplier": [100],
        "periodFinish": "2222-02-22T10:00:23.000Z",
        "isPeriodFinished": false,
        getHypotheticalRewardRate: (stakedAmount: TokenAmount, totalStakedAmount: TokenAmount, totalRewardRate: TokenAmount): TokenAmount => {
          return new TokenAmount(
            new Token(chainId, plebTokenAddress, 18, "PLEB","Plebbit"),
            JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
              ? JSBI.divide(JSBI.multiply(totalRewardRate.raw, stakedAmount.raw), totalStakedAmount.raw)
              : JSBI.BigInt(0)
          )
        }
      }
      setStakingInfo(stakingInfo)
    })()
  }, [pendingToken, timeAdjustedTokenPerBlock, depositedAmount, totalLpBalanceInFarm, totalStakedInWavax, avaxPrice])

  return stakingInfo
}

export function useTimeAdjustedTokenPerBlock(): number | undefined {
  // const provider = new ethers.providers.JsonRpcProvider()
  const contract = useContract(farmAddress, farmAbi)
  const [timeAdjustedTokenPerBlock, setTimeAdjustedTokenPerBlock] = useState()

  // let localProviderContract
  // if (contract) {
  //   localProviderContract = contract.connect(provider)
  // }

  useEffect(() => {
    ;(async () => {
      if (!contract) {
        return
      }
      // if (!localProviderContract) {
      //   return
      // }
      const promises = [
        contract.tokenPerBlock(),
        contract.bonusMultiplier(),
        contract.getPercentBonusTimeLeft()
      ]
      const [tokenPerBlock, bonusMultiplier, percentBonusTimeLeft] = await Promise.all(promises)

      const adjustedTokenPerBlock = tokenPerBlock.mul(bonusMultiplier).mul(percentBonusTimeLeft).div(ethers.BigNumber.from('1000000000000'))
      console.log(tokenPerBlock)
      if (tokenPerBlock.gt(adjustedTokenPerBlock)) {
        setTimeAdjustedTokenPerBlock(tokenPerBlock)
      }
      else {
        setTimeAdjustedTokenPerBlock(adjustedTokenPerBlock)
      }
    })()
  }, [contract])

  return timeAdjustedTokenPerBlock
}

export function useBlocksLeftUntilStartBlock(): string {
  const contract = useContract(farmAddress, farmAbi)
  const [blocks, setBlocks] = useState()
  const blockNumber = useBlockNumber()

  useEffect(() => {
    ;(async () => {
      if (!contract || !blockNumber) {
        return
      }

      let startBlock = await contract.startBlock()
      let number = startBlock.toString() - blockNumber.toString()
      if (number < 0) {
        number = 0
      }
      setBlocks(number)
    })()
  }, [contract, blockNumber])

  return blocks
}

export function useLpBalance(address: string): string {
  // const provider = new ethers.providers.JsonRpcProvider()
  const contract = useContract(lpTokenAddress, ERC20_INTERFACE)
  const [balance, setBalance] = useState()
  const blockNumber = useBlockNumber()

  // let localProviderContract
  // if (contract) {
  //   localProviderContract = contract.connect(provider)
  // }

  useEffect(() => {
    ;(async () => {
      if (!contract) {
        return
      }
      // if (!localProviderContract) {
      //   return
      // }
      if (!address) {
        return
      }
      const res = await contract.balanceOf(address)
      setBalance(res)
    })()
  }, [contract, address, blockNumber])

  return balance
}

export function useWavaxBalance(address: string): string {
  // const provider = new ethers.providers.JsonRpcProvider()
  const contract = useContract(wavaxTokenAddress, ERC20_INTERFACE)
  const [balance, setBalance] = useState()
  const blockNumber = useBlockNumber()

  // let localProviderContract
  // if (contract) {
  //   localProviderContract = contract.connect(provider)
  // }

  useEffect(() => {
    ;(async () => {
      if (!contract) {
        return
      }
      // if (!localProviderContract) {
      //   return
      // }
      if (!address) {
        return
      }
      const res = await contract.balanceOf(address)
      setBalance(res)
    })()
  }, [contract, address, blockNumber])

  return balance
}

export function useUsdcBalance(address: string): string {
  // const provider = new ethers.providers.JsonRpcProvider()
  const contract = useContract(usdcTokenAddress, ERC20_INTERFACE)
  const [balance, setBalance] = useState()
  const blockNumber = useBlockNumber()

  // let localProviderContract
  // if (contract) {
  //   localProviderContract = contract.connect(provider)
  // }

  useEffect(() => {
    ;(async () => {
      if (!contract) {
        return
      }
      // if (!localProviderContract) {
      //   return
      // }
      if (!address) {
        return
      }
      const res = await contract.balanceOf(address)
      setBalance(res)
    })()
  }, [contract, address, blockNumber])

  return balance
}

export function useTotalSupply(tokenAddress: string): string {
  // const provider = new ethers.providers.JsonRpcProvider()
  const contract = useContract(tokenAddress, ERC20_INTERFACE)
  const [supply, setSupply] = useState()

  // let localProviderContract
  // if (contract) {
  //   localProviderContract = contract.connect(provider)
  // }

  useEffect(() => {
    ;(async () => {
      if (!contract) {
        return
      }
      // if (!localProviderContract) {
      //   return
      // }
      if (!tokenAddress) {
        return
      }
      const res = await contract.totalSupply()
      setSupply(res)
    })()
  }, [contract, tokenAddress])

  return supply
}

export function usePlebBalance(address: string): string {
  // const provider = new ethers.providers.JsonRpcProvider()
  const contract = useContract(plebTokenAddress, ERC20_INTERFACE)
  const [balance, setBalance] = useState()
  const blockNumber = useBlockNumber()

  // let localProviderContract
  // if (contract) {
  //   localProviderContract = contract.connect(provider)
  // }

  useEffect(() => {
    ;(async () => {
      if (!contract) {
        return
      }
      // if (!localProviderContract) {
      //   return
      // }
      if (!address) {
        return
      }
      try {
        const res = await contract.balanceOf(address)
        setBalance(res)
      }
      catch (e) {
        
      }

    })()
  }, [contract, address, blockNumber])

  return balance
}

export function useLpFarmAllowance(address: string): string {
  // const provider = new ethers.providers.JsonRpcProvider()
  const contract = useContract(lpTokenAddress, ERC20_INTERFACE)
  const [allowance, setAllowance] = useState()

  // let localProviderContract
  // if (contract) {
  //   localProviderContract = contract.connect(provider)
  // }

  useEffect(() => {
    ;(async () => {
      if (!contract) {
        return
      }
      // if (!localProviderContract) {
      //   return
      // }
      if (!address) {
        return
      }
      const allowance = await contract.allowance(address, farmAddress)
      setAllowance(allowance)
    })()
  }, [contract, address])

  return allowance
}

export function useLpIsApproved(address: string, amount): string {
  const allowance = useLpFarmAllowance(address)
  return allowance.greaterThanOrEqual(amount)
}

export function useTotalLpBalanceInFarm(): string {
  const balance = useLpBalance(farmAddress)
  return balance
}

export function usePendingToken(address: string): string {
  // const provider = new ethers.providers.JsonRpcProvider()
  const contract = useContract(farmAddress, farmAbi)
  const [pendingToken, setPendingToken] = useState()
  const blockNumber = useBlockNumber()

  // let localProviderContract
  // if (contract) {
  //   localProviderContract = contract.connect(provider)
  // }

  const poolId = 0

  useEffect(() => {
    ;(async () => {
      if (!contract) {
        return
      }
      // if (!localProviderContract) {
      //   return
      // }
      if (!address) {
        return
      }
      const res = await contract.pendingToken(poolId, address)
      if (pendingToken && pendingToken.toString() === res.toString()) {
        return
      }
      setPendingToken(res)
    })()
  }, [contract, address, blockNumber])

  return pendingToken
}

export function useDepositedAmount(address: string): string {
  // const provider = new ethers.providers.JsonRpcProvider()
  const contract = useContract(farmAddress, farmAbi)
  const [depositedAmount, setDepositedAmount] = useState()
  const blockNumber = useBlockNumber()

  // let localProviderContract
  // if (contract) {
  //   localProviderContract = contract.connect(provider)
  // }

  const poolId = 0

  useEffect(() => {
    ;(async () => {
      if (!contract) {
        return
      }
      // if (!localProviderContract) {
      //   return
      // }
      if (!address) {
        return
      }
      const {amount} = await contract.userInfo(poolId, address)
      setDepositedAmount(amount)
    })()
  }, [contract, address, blockNumber])

  return depositedAmount
}

// export function useWeeklyPoolRateIfDepositedAmount(depositedAmount: string): number | undefined {
//   const totalLpBalanceInFarm = useTotalLpBalanceInFarm()
//   const weeklyPoolRate = useWeeklyPoolRate()

//   if (totalLpBalanceInFarm && depositedAmount && weeklyPoolRate) {
//     const percentOfPool = depositedAmount.mul(ethers.BigNumber.from('1000000000000')).div(totalLpBalanceInFarm)
//     return weeklyPoolRate.mul(percentOfPool).div('1000000000000')
//   }
// }

// export function useAddressWeeklyPoolRate(address: string): number | undefined {
//   const totalLpBalanceInFarm = useTotalLpBalanceInFarm()
//   const depositedAmount = useDepositedAmount(address)
//   const weeklyPoolRate = useWeeklyPoolRate()

//   if (totalLpBalanceInFarm && depositedAmount && weeklyPoolRate) {
//     const percentOfPool = depositedAmount.mul(ethers.BigNumber.from('1000000000000')).div(totalLpBalanceInFarm)
//     return weeklyPoolRate.mul(percentOfPool).div('1000000000000')
//   }
// }

// export function useWeeklyPoolRate(): number | undefined {
//   // const provider = new ethers.providers.JsonRpcProvider()
//   const contract = useContract(farmAddress, farmAbi)
//   const [weeklyPoolRate, setWeeklyPoolRate] = useState()

//   // let localProviderContract
//   // if (contract) {
//   //   localProviderContract = contract.connect(provider)
//   // }

//   useEffect(() => {
//     ;(async () => {
//       if (!contract) {
//         return
//       }
//       // if (!localProviderContract) {
//       //   return
//       // }
//       const promises = [
//         contract.tokenPerBlock(),
//         contract.bonusMultiplier(),
//         contract.getPercentBonusTimeLeft()
//       ]
//       const [tokenPerBlock, bonusMultiplier, percentBonusTimeLeft] = await Promise.all(promises)

//       const blocksPerWeek = ethers.BigNumber.from(42200 * 7)
//       const adjustedTokenPerBlock = tokenPerBlock.mul(bonusMultiplier).mul(percentBonusTimeLeft).div(ethers.BigNumber.from('1000000000000'))
//       const tokensPerWeek = adjustedTokenPerBlock.mul(blocksPerWeek)
//       setWeeklyPoolRate(tokensPerWeek)
//       // console.log({
//       //   tokenPerBlock: tokenPerBlock.toString(),
//       //   adjustedTokenPerBlock: adjustedTokenPerBlock.toString(),
//       //   bonusMultiplier: bonusMultiplier.toString(), 
//       //   percentBonusTimeLeft: percentBonusTimeLeft.toString(), 
//       //   tokensPerWeek: tokensPerWeek.toString()
//       // })
//     })()
//   }, [contract])

//   return weeklyPoolRate
// }
