// @ts-nocheck
import { CurrencyAmount, JSBI, Token, Trade } from '@pangolindex/sdk'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowDown } from 'react-feather'
import ReactGA from 'react-ga'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonError, ButtonLight, ButtonPrimary, ButtonConfirmed } from '../../components/Button'
import Card, { GreyCard } from '../../components/Card'
import Column, { AutoColumn } from '../../components/Column'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import CurrencyInputPanel2 from '../../components/CurrencyInputPanel2'
import CurrencyInputPanel4 from '../../components/CurrencyInputPanel4'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { AutoRow, RowBetween } from '../../components/Row'
import AdvancedSwapDetailsDropdown from '../../components/swap/AdvancedSwapDetailsDropdown'
import BetterTradeLink, { DefaultVersionLink } from '../../components/swap/BetterTradeLink'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from '../../components/swap/styleds'
import TradePrice from '../../components/swap/TradePrice'
import TokenWarningModal from '../../components/TokenWarningModal'
import ProgressSteps from '../../components/ProgressSteps'
import { Link } from 'react-router-dom'
import { injected } from '../../connectors'

import { INITIAL_ALLOWED_SLIPPAGE, TRUSTED_TOKEN_ADDRESSES } from '../../constants'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useToggledVersion, { DEFAULT_VERSION, Version } from '../../hooks/useToggledVersion'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useToggleSettingsMenu, useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState
} from '../../state/swap/hooks'
import { useExpertModeManager, useUserSlippageTolerance } from '../../state/user/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import AppBody from '../AppBody2'
import { ClickableText } from '../Pool/styleds'
import Loader from '../../components/Loader'
import useENS from '../../hooks/useENS'
import { Trans, useTranslation } from 'react-i18next'
import { useIsSelectedAEBToken, useSelectedTokenList, useTokenList } from '../../state/lists/hooks'
import { DeprecatedWarning } from '../../components/Warning'
import { isTokenOnList } from '../../utils'
import { DEFI_TOKEN_LIST, AVAX_BRIDGE_LIST } from '../../constants/lists'
import nft1Bg2 from '../../assets/plebbit/nft1/bg2-5.png'
import bottomImage from '../../assets/plebbit/nft1/bottom2.png'
import roasterImage from '../../assets/plebbit/nft1/roaster.png'
import networkImage from '../../assets/plebbit/nft1/network.png'
import tvImage from '../../assets/plebbit/nft1/tv.png'
import wavesImage from '../../assets/plebbit/nft1/waves.png'

// new stuff
// import { usePlebBalance, usePlebAirdropBalance, useAirdropIsClaimed, useClaimAirdrop } from '../../plebbit/hooks/nft1'
import Countdown from 'react-countdown'
import { useBuyXpleb } from '../../plebbit/hooks/nft1'
import { price, chainId as xplebChainId } from '../../plebbit/xplebBuySettings'

const Container1 = styled.div`
  height: calc(100vh - 150px);
  /* background-color: red; */
`

const Container2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  background: url(${nft1Bg2}), linear-gradient(to bottom,#00000000,#b4b3b4 10%,#b4b3b4);
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100vw;
  margin-top: -50px;
  padding-top: 150px;
  @media (max-width: 960px) {
    margin-top: -30px;
    padding-top: 100px;
  }
  @media (max-width: 470px) {
    margin-top: -30px;
    padding-top: 80px;
  }
`

const Section2Container = styled.div`
  padding-top: 36px;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
`

const Section2BContainer = styled.div`
  padding-left: 92px;
  padding-right: 92px;
  @media (max-width: 960px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (max-width: 470px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`

const Container3 = styled.div`
  width: 100vw;
  background: #b4b3b4;
  margin-bottom: -80px;
  @media (min-width: 2000px) {
    background: #a8a8a8;
  }
  @media (min-width: 1700px) {
    background: #aeadae;
  }
`

const BottomImage = styled.img`
  width: 100%;
  height: 100%;
`

const TitleText = styled.span`
  font-size: 8vw;
  font-weight: 500;
  color: #c4c4c4;
  line-height: 0.9;
  @media (max-width: 960px) {
    font-size: 88px;
  }
  @media (max-width: 470px) {
    font-size: 20vw;
  }
`

const TitleContainer = styled.div`
  background: #a7a7a7;
  padding: 0px 12px;
  margin-top: -10vh;
  @media (max-width: 960px) {
    padding: 0px 12px;
    margin-top: 0;
  }
  @media (max-width: 470px) {
    padding: 0px 12px;
    margin-top: 5vh;
  }
`

const NftColumn = styled.div`
  flex: 1 470px;
  min-width: 0;
  margin-left: 92px;
  @media (max-width: 960px) {
    margin-left: 24px;
    margin-right: 24px;
  }
  @media (max-width: 470px) {
    margin-left: 24px;
    margin-right: 24px;
  }
  &:nth-of-type(2) {
    /*margin-left: 0;*/
    /* padding-left: 92px; */
  }
`

const RoasterImage = styled.img`
  width: 125%;
  margin-left: -12%;
`

const NetworkImage = styled.img`
  transform: scale(1.075) translate(-110px,-70px);
  transform: scale(1.175) translate(-110px,-60px);
  position: absolute;
  @media (max-width: 1131px) {
    position: unset;
    transform: scale(1.175) translate(35px,-60px);
  }
  @media (max-width: 960px) {
    position: unset;
    transform: scale(1.175) translate(35px,-50px);
  }
  @media (max-width: 470px) {
    position: unset;
    transform: scale(1.175) translate(-40px,-35px);
    width: 125%;
  }
`

const TextLink = styled.a`
  color: #000000;
  font-weight: 400;
`

const NftImage = styled.img`
  width: 200%;
  transform: translate(-150px, 0px);
  @media (max-width: 1123px) {
    transform: translate(-18vw,-123px);
    margin-bottom: -220px;
    width: 140%;
  }
`

const TvImage = styled.img`
  width: 200%;
  transform: translate(-150px, 0px);
  top: 0;
  position: absolute;
  display: block;
  @media (max-width: 1123px) {
    transform: translate(-30vw,-370px);
    margin-bottom: -220px;
    width: 170%;
    margin-top: -120px;
  }
  @media (max-width: 900px) {
    transform: translate(-30vw,-52vw);
    margin-bottom: 0;
    margin-top: 0;
    width: 170%;
  }
`

const WavesImage = styled.img`
  width: 200%;
  transform: translate(-150px, 0px);
  opacity: 0.75;
  @media (max-width: 1123px) {
    width: 140%;
    opacity: 0.35;
    transform: translate(-18vw,-323px);
    margin-bottom: -600px;
    margin-top: -120px;
  }
  @media (max-width: 900px) {
    transform: translate(-18vw,-37vw);
    margin-bottom: -80vw;
    margin-top: 0;
    width: 140%;
    opacity: 0.35;
  }
`

const Content1TitleContainer = styled.div`
  background: #e2e2e2;
  padding: 0px 12px;
  @media (max-width: 960px) {
    padding: 0px 12px;
  }
  @media (max-width: 470px) {
    padding: 0px 12px;
  }
`

const Content1Container = styled.div`
  background-image: linear-gradient(90deg, #e2e2e2, #e2e2e2 89%, #00000000 89%, #00000000);
  position: relative;
  z-index: 100;
  padding: 0px 12px;
  padding-bottom: 12px;
  @media (max-width: 960px) {
    padding: 0px 12px;
    padding-bottom: 12px;
    background-image: linear-gradient(90deg, #e2e2e2, #e2e2e2);
  }
  @media (max-width: 470px) {
    padding: 0px 12px;
    padding-bottom: 12px;
    background-image: linear-gradient(180deg,#e2e2e2,#9c9c9c61);
  }
`

const Content1BTitleContainer = styled.div`
  background: #e2e2e2;
  padding: 0px 12px;
  @media (max-width: 960px) {
    padding: 0px 12px;
  }
  @media (max-width: 470px) {
    padding: 0px 12px;
    background: #9c9c9c61;
  }
`

const Content1BContainer = styled.div`
  background-image: linear-gradient(90deg, #e2e2e2, #e2e2e2 89%, #00000000 89%, #00000000);
  position: relative;
  z-index: 100;
  padding: 0px 12px;
  padding-bottom: 12px;
  @media (max-width: 960px) {
    padding: 0px 12px;
    padding-bottom: 12px;
    background-image: linear-gradient(180deg,#e2e2e2,#00000000);
  }
  @media (max-width: 470px) {
    padding: 0px 12px;
    padding-bottom: 12px;
    background-image: linear-gradient(180deg,#9c9c9c61,#00000000);
  }
`

const Content1CTitleContainer = styled.div`
  padding-top: 12px;
  @media (max-width: 960px) {
  }
  @media (max-width: 470px) {
  }
`

const Content1CContainer = styled.div`
  position: relative;
  z-index: 100;
  padding-bottom: 12px;
  @media (max-width: 960px) {

  }
  @media (max-width: 470px) {

  }
`

const Content1Title = styled.div`
  font-size: 68px;
  font-weight: 500;
  color: #000000;
  line-height: 0.9;
  padding-bottom: 24px;
  @media (max-width: 960px) {
  }
  @media (max-width: 470px) {
    .noSmall {
      display: none;
    }
  }
`

const Content1TitleHyphens = styled.div`
  hyphens: auto;
  font-size: 68px;
  font-weight: 500;
  color: #000000;
  line-height: 0.9;
  padding-bottom: 24px;
  @media (max-width: 960px) {
  }
  @media (max-width: 470px) {
    .noSmall {
      display: none;
    }
  }
`

const Content1FaqTitle = styled.div`
  font-size: 28px;
  font-weight: 500;
  color: #000000;
  line-height: 1.1;
  padding-bottom: 24px;
  @media (max-width: 960px) {
  }
  @media (max-width: 470px) {
    .noSmall {
      display: none;
    }
  }
`

const Content1Text = styled.div`
  font-size: 27px;
  font-weight: 300;
  color: #050505;
  line-height: 1.2;
  padding-bottom: 12px;
  @media (max-width: 960px) {
  }
  @media (max-width: 470px) {
  }
`

const TopText = styled.span`
  margin-bottom: 8px;
  font-size: 18px;
`

const BottomText = styled.span`
  margin-top: 8px;
  font-size: 18px;
  padding: 0 24px;
`

const VeloxLink = styled.a`
  color: #A4A4A4;
  text-decoration: none;
`

const MarginswapLink = styled.a`
  color: #A4A4A4;
  text-decoration: none;
  margin-right: 5px;
  margin-left: 5px;
`

const WowSwapLink = styled.a`
  color: #A4A4A4;
  text-decoration: none;
  margin-left: 5px;
`

const WarningWrapper = styled.div`
  max-width: 420px;
  width: 100%;
  margin: 0 0 2rem 0;
`

const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
     flex-direction: column;
     gap: 12px;
   `};
`

const Container1Button = styled(ButtonPrimary)`
  border-radius: 0;
  text-transform: uppercase;
  color: #c4c4c4;
  background-color: #a7a7a7;
  padding: 0 2px;
  width: auto;
  font-weight: 600;
  font-size: 30px;
  height: 30px;
`

const ButtonErrorStyled = styled(ButtonError)`
  &:disabled {
    background-color: #f7f8fa09
  }
`

export default function Swap() {
  const loadedUrlParams = useDefaultsFromURLSearch()
  const { t } = useTranslation()

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId)
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  const { account, chainId } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const toggleSettings = useToggleSettingsMenu()
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    v1Trade,
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError
  } = useDerivedSwapInfo()
  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENS(recipient)
  const toggledVersion = useToggledVersion()
  const tradesByVersion = {
    [Version.v1]: v1Trade,
    [Version.v2]: v2Trade
  }
  const trade = showWrap ? undefined : tradesByVersion[toggledVersion]
  const defaultTrade = showWrap ? undefined : tradesByVersion[DEFAULT_VERSION]

  const betterTradeLinkVersion: Version | undefined = undefined

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
      }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      value = value.replace(/[^0-9]/g, '')
      value = value.replace(/^0+/, '')
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )
  const noRoute = !route

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(trade, allowedSlippage, recipient)

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  const handleSwap = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
      return
    }
    if (!swapCallback) {
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then(hash => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })

        ReactGA.event({
          category: 'Swap',
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === account
              ? 'Swap w/o Send + recipient'
              : 'Swap w/ Send',
          label: [trade?.inputAmount?.currency?.symbol, trade?.outputAmount?.currency?.symbol, Version.v2].join('/')
        })
      })
      .catch(error => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined
        })
      })
  }, [tradeToConfirm, account, priceImpactWithoutFee, recipient, recipientAddress, showConfirm, swapCallback, trade])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    inputCurrency => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(outputCurrency => onCurrencySelection(Field.OUTPUT, outputCurrency), [
    onCurrencySelection
  ])

  const isAEBToken = useIsSelectedAEBToken()

  const selectedTokens = useSelectedTokenList()
  const whitelistedTokens = useTokenList([DEFI_TOKEN_LIST, AVAX_BRIDGE_LIST])

  const isTrustedToken = useCallback(
    (token: Token) => {
      if (!chainId || !selectedTokens) return true // Assume trusted at first to avoid flashing a warning
      return (
        TRUSTED_TOKEN_ADDRESSES[chainId].includes(token.address) || // trust token from manually whitelisted token
        isTokenOnList(selectedTokens, token) || // trust all tokens from selected token list by user
        isTokenOnList(whitelistedTokens, token) // trust all defi + AB tokens
      )
    },
    [chainId, selectedTokens, whitelistedTokens]
  )

  const countdownRenderer = (countdownProps: CountdownRenderProps) => {
    const { days, hours, minutes, seconds } = countdownProps
    const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds
    const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes
    const paddedHours = hours < 10 ? `0${hours}` : hours
    let string = `${paddedHours}:${paddedMinutes}:${paddedSeconds}`
    if (days) {
      string = `${days} ${days === 1 ? 'day' : 'days'} and ${hours} ${hours === 1 ? 'hour' : 'hours'}`
    }
    return (
      <span style={{ width: '100%' }}>
        {string}
      </span>
    )
  }

  let _account = '0x5Bc4FF33f86E0272be53Fa25861294489AB2FE2a' // my account for testing
  _account = account // comment this to test stuff with random account
  const airdropHasStartedTimestamp = 1642180464499
  const airdropHasStarted = true
  const _yourAirdrop = 1000000000000000 //usePlebAirdropBalance(_account)
  let yourBalance = 10000000000000000 //usePlebBalance(_account)
  let yourAirdrop = '-'
  if (yourBalance !== '-') {
    yourAirdrop = _yourAirdrop
  }

  function addPolygonNetwork() {
    const POLYGON_CHAIN_PARAMS = {
      chainId: '0x89', // A 0x-prefixed hexadecimal chainId
      chainName: 'Polygon Mainnet',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18
      },
      rpcUrls: ['https://polygon-rpc.com/'],
      blockExplorerUrls: ['https://polygonscan.com']
    }
    
    injected.getProvider().then(provider => {
      provider
        .request({
          method: 'wallet_addEthereumChain',
          params: [POLYGON_CHAIN_PARAMS]
        })
        .catch((error: any) => {
          console.log(error)
        })
    })
  }

  const totalPrice = formattedAmounts[Field.INPUT] ? formattedAmounts[Field.INPUT] * 100 : formattedAmounts[Field.INPUT]

  const buyXpleb = useBuyXpleb()
  let _buyXpleb = async () => {
    const _totalPrice = String(totalPrice * price)
    // console.log({amount: formattedAmounts[Field.INPUT], value: _totalPrice})
    try {
      await buyXpleb(formattedAmounts[Field.INPUT], {value: _totalPrice})
    }
    catch (e) {
      console.log(e)
      window.alert(e?.message + ': ' + e?.data?.message)
    }
  }

  const airdropIsClaimed = false // useAirdropIsClaimed(_account)
  let claimButtonIsActive = true
  let claimButton = 'Mint'
  if (formattedAmounts[Field.INPUT] === '' || formattedAmounts[Field.INPUT] === '0') {
    claimButton = 'Enter an amount'
    claimButtonIsActive = false
  }
  if (xplebChainId !== chainId) {
    claimButton = 'Switch to Polygon chain'
    claimButtonIsActive = true
    _buyXpleb = () => addPolygonNetwork()
  }
  // if (airdropHasStarted) {
  //   if (airdropIsClaimed === true) {
  //     claimButton = 'Airdrop already claimed'
  //   }
  //   if (airdropIsClaimed === false) {
  //     claimButton = 'Claim airdrop'
  //   }
  // }
  // else {
  //   claimButton = <span>
  //     Claim airdrop in {' '}
  //     <Countdown renderer={countdownRenderer} date={airdropHasStartedTimestamp} />
  //   </span>
  // }
  // if (airdropIsClaimed === undefined || yourAirdrop === '-') {
  //   claimButton = 'Loading...'
  // }
  // if (airdropHasStarted && airdropIsClaimed === false && yourAirdrop !== '-' && yourAirdrop !== '0.0') {
  //   claimButtonIsActive = true
  // }

  const claimAirdrop = () => {} // useClaimAirdrop()
  const maticCurrency = {decimals: 18, name: "Polygon", symbol: "MATIC"}
  const xplebCurrency = {decimals: 18, name: "ExosPleb", symbol: "XPLEB"}

  let AirdropCard = <div id='mint' style={{display: 'flex', flexDirection: 'column', margin: 'auto'}}>
      <TokenWarningModal
        isOpen={urlLoadedTokens.length > 0 && !dismissTokenWarning && !urlLoadedTokens.every(isTrustedToken)}
        tokens={urlLoadedTokens}
        onConfirm={handleConfirmTokenWarning}
      />

      {isAEBToken && (
        <WarningWrapper>
          <DeprecatedWarning />
        </WarningWrapper>
      )}

        {/*<TopText>
          All proceeds are used to buy and burn PLEB
        </TopText> */}

      <AppBody>
        <Wrapper id="swap-page">
          <ConfirmSwapModal
            isOpen={showConfirm}
            trade={trade}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            recipient={recipient}
            allowedSlippage={allowedSlippage}
            onConfirm={handleSwap}
            swapErrorMessage={swapErrorMessage}
            onDismiss={handleConfirmDismiss}
          />

          <AutoColumn gap={'md'}>
            <CurrencyInputPanel4
              label="Amount"
              value={formattedAmounts[Field.INPUT]}
              showMaxButton={false}
              // currency={currencies[Field.INPUT]}
              currency={xplebCurrency}
              onUserInput={handleTypeInput}
              onMax={handleMaxInput}
              onCurrencySelect={handleInputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              id="swap-currency-input"
            />

           <CurrencyInputPanel4
              label="Price"
              value={totalPrice}
              showMaxButton={false}
              onUserInput={() => {}}
              // currency={currencies[Field.INPUT]}
              currency={maticCurrency}
              otherCurrency={currencies[Field.OUTPUT]}
              id="swap-currency-input"
            />

            {showWrap ? null : (
              <Card padding={'.25rem .75rem 0 .75rem'} borderRadius={'20px'}>
                <AutoColumn gap="4px">
                  {Boolean(trade) && (
                    <RowBetween align="center">
                      <Text fontWeight={500} fontSize={14} color={theme.text2}>
                        {t('swapPage.price')}
                      </Text>
                      <TradePrice
                        price={trade?.executionPrice}
                        showInverted={showInverted}
                        setShowInverted={setShowInverted}
                      />
                    </RowBetween>
                  )}
                  {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                    <RowBetween align="center">
                      <ClickableText fontWeight={500} fontSize={14} color={theme.text2} onClick={toggleSettings}>
                        {t('swapPage.slippageTolerance')}
                      </ClickableText>
                      <ClickableText fontWeight={500} fontSize={14} color={theme.text2} onClick={toggleSettings}>
                        {allowedSlippage / 100}%
                      </ClickableText>
                    </RowBetween>
                  )}
                </AutoColumn>
              </Card>
            )}
          </AutoColumn>
          <BottomGrouping>
            {!account ? (
              <ButtonLight onClick={toggleWalletModal}>{t('swapPage.connectWallet')}</ButtonLight>
            ) : showWrap ? (
              <ButtonPrimary disabled={!claimButtonIsActive} onClick={onWrap}>
                {wrapInputError ??
                  (wrapType === WrapType.WRAP
                    ? t('swapPage.wrap')
                    : wrapType === WrapType.UNWRAP
                    ? t('swapPage.unwrap')
                    : null)}
              </ButtonPrimary>
            ) : noRoute && userHasSpecifiedInputOutput ? (
              <GreyCard style={{ textAlign: 'center' }}>
                <TYPE.main mb="4px">{t('swapPage.insufficientLiquidity')}</TYPE.main>
              </GreyCard>
            ) : showApproveFlow ? (
              <RowBetween>
                <ButtonConfirmed
                  onClick={() => _buyXpleb()}
                  disabled={!claimButtonIsActive}
                  width="48%"
                  altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                  confirmed={approval === ApprovalState.APPROVED}
                >
                  {approval === ApprovalState.PENDING ? (
                    <AutoRow gap="6px" justify="center">
                      {t('swapPage.approving')} <Loader stroke="white" />
                    </AutoRow>
                  ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                    t('swapPage.approved')
                  ) : (
                    t('swapPage.approve') + currencies[Field.INPUT]?.symbol
                  )}
                </ButtonConfirmed>
                <ButtonErrorStyled
                  onClick={() => _buyXpleb()}
                  width="48%"
                  id="swap-button"
                  disabled={
                    !claimButtonIsActive
                  }
                  error={isValid && priceImpactSeverity > 2}
                >
                  <Text fontSize={16} fontWeight={500}>
                    {priceImpactSeverity > 3 && !isExpertMode
                      ? t('swapPage.priceImpactHigh')
                      : t('swapPage.swap') + `${priceImpactSeverity > 2 ? t('swapPage.anyway') : ''}`}
                  </Text>
                </ButtonErrorStyled>
              </RowBetween>
            ) : (
              <ButtonErrorStyled
                onClick={() => _buyXpleb()}
                id="swap-button"
                disabled={!claimButtonIsActive}
                error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
              >
                <Text fontSize={20} fontWeight={500}>
                  {claimButton}
                </Text>
              </ButtonErrorStyled>
            )}
            {showApproveFlow && (
              <Column style={{ marginTop: '1rem' }}>
                <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />
              </Column>
            )}
            {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
            {betterTradeLinkVersion ? (
              <BetterTradeLink version={betterTradeLinkVersion} />
            ) : toggledVersion !== DEFAULT_VERSION && defaultTrade ? (
              <DefaultVersionLink />
            ) : null}
          </BottomGrouping>
        </Wrapper>
      </AppBody>

      <AdvancedSwapDetailsDropdown trade={trade} />

      <BottomText>
        All proceeds are used to buy and burn PLEB
      </BottomText>
    </div>

  return <> 
    <Container1>
      <TitleContainer>
        <TitleText>PLEBBIT NFTS</TitleText>
      </TitleContainer>
      <DataRow style={{ height: '50vh', flexDirection: 'column'}}>
        <Container1Button onClick={() => {
          document.getElementById('mint').scrollIntoView({behavior: 'smooth'})
        }}>
          MINT
        </Container1Button>

        <Container1Button
          as='a'
          href='https://opensea.io/collection/xpleb'
          target='_blank'
        >
          OPENSEA
        </Container1Button>
      </DataRow>
    </Container1>
    <Container2>
      <NftColumn>
        <Content1TitleContainer>
          <Content1Title>EXO'S PLEBS NFT <span className='noSmall'>COLLECTION</span></Content1Title>
        </Content1TitleContainer>
        <Content1Container>
          <Content1Text>
            Exo's Plebs (ticker: XPLEB) are a set of 10,000 image NFTs each with a set of unique attributes. All art and metadata are IPFS URLs (decentralized and immutable, similar to BAYC) and will be tradable on OpenSea. 
          </Content1Text>
          <Content1Text>
            The only way to get a PFP on plebbit is to aquire one of the curated NFT collection like Exo's Plebs. Other benefits include DAO voting power and reduced captchas.
          </Content1Text>
        </Content1Container>
        <Content1BTitleContainer>
          <Content1Title>XPLEB MINTING</Content1Title>
        </Content1BTitleContainer>
        <Content1BContainer>
          <Content1Text>
            You can mint an Exo's Pleb for 100 MATIC on the Polygon network until the total supply of 10,000 is reached. All proceeds are used to buy and burn the PLEB token (not for profit).
          </Content1Text>
        </Content1BContainer>
      </NftColumn>
      <NftColumn>
        <NetworkImage src={networkImage}/>
      </NftColumn>
      <RoasterImage src={roasterImage}/>
      <Section2Container>
        <NftColumn>
          <Content1CTitleContainer>
            <Content1TitleHyphens>PLEBBIT PROTOCOL</Content1TitleHyphens>
          </Content1CTitleContainer>
          <Content1CContainer>
            <Content1Text>
              Plebbit is a protocol and tooling to create decentralized message boards like Reddit, Discourse, 4chan, Facebook groups, old school forums, etc. It is a pure peer to peer protocol, it doesn't use any central server, central database, DNS, or public HTTP endpoint. It doesn't have a website or interface served by a central party (like Uniswap for example).
            </Content1Text>
            <Content1Text>
              It does not use a blockchain so it has no transaction fees to post, rather each community / board / forum / subreddit is like a torrent file that is updated every 5 minutes. It prevents spam by having community owners run a challenge service (captcha or other) over peer to peer pubsub. Peers who fail too many challenges are blocked from the pubsub. <TextLink target='_blank' href='https://github.com/plebbit/whitepaper/discussions/2'>Read the whitepaper</TextLink>
            </Content1Text>

          <Content1CTitleContainer>
            <Content1Title>PLEBBIT CLIENT</Content1Title>
          </Content1CTitleContainer>
          <Content1CContainer>
            <Content1Text>
              The plebbit desktop client will be released in a few months, it will have a user interface identical to Reddit, and allow anyone to create a subplebbit from their home computer and internet connection (similar to creating a torrent file). 
            </Content1Text>
            <Content1Text>
              Peers will be able to join the P2P swarm and read/publish posts to subplebbits, without any intermediaries, servers, DNS, HTTP endpoints, etc. A web client will also be available and eventually a native mobile client.
            </Content1Text>
          </Content1CContainer>
          </Content1CContainer>
        </NftColumn>
        <NftColumn style={{position: 'relative'}}>
          <WavesImage src={wavesImage}/>
          <TvImage src={tvImage}/>
        </NftColumn>
        <Section2BContainer>
          <Content1CTitleContainer>
            <Content1FaqTitle>PLEBBIT DAO</Content1FaqTitle>
          </Content1CTitleContainer>
          <Content1CContainer>
            <Content1Text>
              The plebbit DAO will be used to make decisions such as which projects/members should be funded, which NFT collection should be curated by default, default multisubs, etc.
            </Content1Text>
            <Content1CTitleContainer>
              <Content1FaqTitle>PLEBBIT IS HIRING JS DEVS</Content1FaqTitle>
            </Content1CTitleContainer>
            <Content1Text>
              Plebbit is hiring JS devs <TextLink style={{wordBreak: 'break-all'}} target='_blank' href='https://medium.com/@plebbit/plebbit-is-hiring-javascript-developers-cc20980b42ae'>https://medium.com/@plebbit/plebbit-is-hiring-javascript-developers-cc20980b42ae</TextLink>
            </Content1Text>
            <Content1CTitleContainer>
              <Content1FaqTitle>WHAT IS THE TOTAL XPLEB SUPPLY?</Content1FaqTitle>
            </Content1CTitleContainer>
            <Content1Text>
              10,000
            </Content1Text>
            <Content1CTitleContainer>
              <Content1FaqTitle>HOW MUCH DOES AN XPLEB COST TO MINT?</Content1FaqTitle>
            </Content1CTitleContainer>
            <Content1Text>
              100 MATIC
            </Content1Text>
          </Content1CContainer>
        </Section2BContainer>
        {AirdropCard}
      </Section2Container>
    </Container2>
    <Container3>
      <BottomImage src={bottomImage}/>
    </Container3>
  </>
}
