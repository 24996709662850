import {xplebPlebLockerAddress, xplebLpLockerAddress, plebTokenAddress, lpTokenAddress} from './settings'

const settings = [
  {nftMintDate: 'May 5th 2022', nftTicker: 'XPLEB', lockIndex: 0, lockerAddress: xplebPlebLockerAddress, lockTokenAddress: plebTokenAddress, lockTokenTicker: 'PLEB', lockAmount: '600M', lockTime: '3 MONTHS'},
  {nftMintDate: 'May 5th 2022', nftTicker: 'XPLEB', lockIndex: 1, lockerAddress: xplebPlebLockerAddress, lockTokenAddress: plebTokenAddress,  lockTokenTicker: 'PLEB', lockAmount: '300M', lockTime: '6 MONTHS'},
  {nftMintDate: 'May 5th 2022', nftTicker: 'XPLEB', lockIndex: 2, lockerAddress: xplebPlebLockerAddress, lockTokenAddress: plebTokenAddress,  lockTokenTicker: 'PLEB', lockAmount: '100M', lockTime: '12 MONTHS'},
  {nftMintDate: 'May 5th 2022', nftTicker: 'XPLEB', lockIndex: 0, lockerAddress: xplebLpLockerAddress, lockTokenAddress: lpTokenAddress,  lockTokenTicker: 'PLEB-AVAX', lockAmount: '10K', lockTime: '3 MONTHS'},
  {nftMintDate: 'May 5th 2022', nftTicker: 'XPLEB', lockIndex: 1, lockerAddress: xplebLpLockerAddress, lockTokenAddress: lpTokenAddress,  lockTokenTicker: 'PLEB-AVAX', lockAmount: '5K', lockTime: '6 MONTHS'},
  {nftMintDate: 'May 5th 2022', nftTicker: 'XPLEB', lockIndex: 2, lockerAddress: xplebLpLockerAddress, lockTokenAddress: lpTokenAddress,  lockTokenTicker: 'PLEB-AVAX', lockAmount: '2K', lockTime: '12 MONTHS'}
]

export default settings
