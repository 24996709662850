// @ts-nocheck
import React, { useState, useCallback } from 'react'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonConfirmed, ButtonError } from '../Button'
import ProgressCircles from '../ProgressSteps'
import CurrencyInputPanel from '../CurrencyInputPanel'
import { TokenAmount, Token, Pair, ChainId } from '@pangolindex/sdk'
import { useActiveWeb3React } from '../../hooks'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { usePairContract, useStakingContract } from '../../hooks/useContract'
import { useApproveCallback, ApprovalState } from '../../hooks/useApproveCallback'
import { splitSignature } from 'ethers/lib/utils'
import { DoubleSideStakingInfo, useDerivedStakeInfo, useMinichefPools } from '../../state/stake/hooks'
import { wrappedCurrencyAmount } from '../../utils/wrappedCurrency'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { LoadingView, SubmittedView } from '../ModalViews'
import { useTranslation } from 'react-i18next'

import {lpTokenAddress, plebTokenAddress, chainId} from '../../plebbit/settings'
import {usePlebToken, useWavaxToken,} from '../../plebbit/hooks/farm'
import {useLockerContract} from '../../plebbit/hooks/nftLock'

const HypotheticalRewardRate = styled.div<{ dim: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;

  opacity: ${({ dim }) => (dim ? 0.5 : 1)};
`

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: DoubleSideStakingInfo
  userLiquidityUnstaked: TokenAmount | undefined
  version: number
}

export default function StakingModal({ isOpen, onDismiss, stakingInfo, userLiquidityUnstaked, version }: StakingModalProps) {
  const { account, chainId, library } = useActiveWeb3React()

  const mockToken = new Token(chainId, stakingInfo.lockTokenAddress, 18, "PLEB","PLEB")

  // track and parse user input
  const [typedValue, setTypedValue] = useState('')
  const { parsedAmount, error } = useDerivedStakeInfo(typedValue, stakingInfo?.stakedAmount?.token || mockToken, userLiquidityUnstaked)
  const parsedAmountWrapped = wrappedCurrencyAmount(parsedAmount, chainId)

  const minAmount = stakingInfo?.lockAmount?.replace('M', '000000').replace('K', '000')
  const nftSpots = Math.floor(parsedAmountWrapped?.toSignificant(4) / minAmount) || 0

  let hypotheticalRewardRate: TokenAmount = new TokenAmount(stakingInfo?.rewardRate?.token || mockToken, '0')
  if (parsedAmountWrapped?.greaterThan('0')) {
    hypotheticalRewardRate = stakingInfo.getHypotheticalRewardRate(
      stakingInfo.stakedAmount.add(parsedAmountWrapped),
      stakingInfo.totalStakedAmount.add(parsedAmountWrapped),
      stakingInfo.totalRewardRate
    )
  }

  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])

  // pair contract for this token to be staked
  // const dummyPair = new Pair(
  //   new TokenAmount(stakingInfo.tokens[1], '0'),
  //   new TokenAmount(stakingInfo.tokens[0], '0'),
  //   chainId ? chainId : ChainId.AVALANCHE
  // )
  const plebToken = usePlebToken()
  const wavaxToken = useWavaxToken()
  let dummyPair = {
    token0: plebToken,
    token1: wavaxToken,
    liquidityToken: {address: lpTokenAddress}
  }
  const pairContract = usePairContract(dummyPair.liquidityToken.address)

  // approval data for stake
  const deadline = useTransactionDeadline()
  const { t } = useTranslation()
  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  const [approval, approveCallback] = useApproveCallback(parsedAmount, stakingInfo?.lockerAddress)

  // const stakingContract = useStakingContract(stakingInfo.stakingRewardAddress)
  const stakingContract = useLockerContract(stakingInfo?.lockerAddress)

  // const poolMap = useMinichefPools()
  const poolMap = new Map()

  async function onStake() {
    if (stakingContract && poolMap && parsedAmount && deadline) {
      setAttempting(true)
      const method = 'lock'
      // const method = version < 2 ? 'stake' : 'deposit'
      // const args = version < 2
      //   ? [`0x${parsedAmount.raw.toString(16)}`]
      //   : [poolMap[stakingInfo.stakedAmount.token.address], `0x${parsedAmount.raw.toString(16)}`, account]
      const args = [stakingInfo?.lockIndex, `0x${parsedAmount.raw.toString(16)}`]

      if (approval === ApprovalState.APPROVED) {
        stakingContract
          [method](...args)
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: 'Locked tokens'
            });
            setHash(response.hash);
          })
          .catch((error: any) => {
            setAttempting(false);
            console.error(error);
            error?.data?.message && window.alert(error?.data?.message)
          })
      } else if (signatureData) {
        const permitMethod = 'lockWithPermit'
        // const permitMethod = version < 2 ? 'stakeWithPermit' : 'depositWithPermit'
        // const permitArgs = version < 2
        //   ? [`0x${parsedAmount.raw.toString(16)}`, signatureData.deadline, signatureData.v, signatureData.r, signatureData.s]
        //   : [poolMap[stakingInfo.stakedAmount.token.address], `0x${parsedAmount.raw.toString(16)}`, account, signatureData.deadline, signatureData.v, signatureData.r, signatureData.s]
        const permitArgs = [stakingInfo?.lockIndex, `0x${parsedAmount.raw.toString(16)}`, signatureData.deadline, signatureData.v, signatureData.r, signatureData.s]

        stakingContract
          [permitMethod](...permitArgs)
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: 'Locked tokens'
            })
            setHash(response.hash)
          })
          .catch((error: any) => {
            setAttempting(false)
            console.error(error)
            error?.data?.message && window.alert(error?.data?.message)
          })
      } else {
        setAttempting(false)
        throw new Error(t('earn.attemptingToStakeError'))
      }
    }
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    setSignatureData(null)
    setTypedValue(typedValue)
  }, [])

  // used for max input button
  const maxAmountInput = maxAmountSpend(userLiquidityUnstaked)
  const atMaxAmount = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))
  const handleMax = useCallback(() => {
    maxAmountInput && onUserInput(maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  async function onAttemptToApprove() {
    // pleb token can't lock with permit
    if (stakingInfo?.lockTokenTicker === 'PLEB') {
      approveCallback()
      return
    }

    if (!pairContract || !library || !deadline) throw new Error(t('earn.missingDependencies'))

    const liquidityAmount = parsedAmount
    if (!liquidityAmount) throw new Error(t('earn.missingLiquidityAmount'))

    // try to gather a signature for permission
    const nonce = await pairContract.nonces(account)

    const EIP712Domain = [
      { name: 'name', type: 'string' },
      { name: 'version', type: 'string' },
      { name: 'chainId', type: 'uint256' },
      { name: 'verifyingContract', type: 'address' }
    ]
    const domain = {
      name: 'Joe LP Token',
      version: '1',
      chainId: chainId,
      verifyingContract: stakingInfo?.lockTokenAddress
    }
    const Permit = [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
      { name: 'value', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'deadline', type: 'uint256' }
    ]
    const message = {
      owner: account,
      spender: stakingInfo?.lockerAddress,
      value: liquidityAmount.raw.toString(),
      nonce: nonce.toHexString(),
      deadline: deadline.toNumber()
    }
    const data = JSON.stringify({
      types: {
        EIP712Domain,
        Permit
      },
      domain,
      primaryType: 'Permit',
      message
    })

    library
      .send('eth_signTypedData_v4', [account, data])
      .then(splitSignature)
      .then(signature => {
        setSignatureData({
          v: signature.v,
          r: signature.r,
          s: signature.s,
          deadline: deadline.toNumber()
        })
      })
      .catch(error => {
        // for all errors other than 4001 (EIP-1193 user rejected request), fall back to manual approve
        if (error?.code !== 4001) {
          approveCallback()
        }
      })
  }

  // console.log({signatureData, parsedAmount, stakingInfo, userLiquidityUnstaked, hypotheticalRewardRate, maxAmountInput, atMaxAmount, approval})
  if (stakingInfo.lockTokenTicker === 'PLEB') {
    dummyPair = undefined
  }

  let depositText = error ?? t('earn.deposit')
  if (!error && typedValue < minAmount) {
    depositText = 'Amount too small'
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>{t('earn.deposit')}</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          <CurrencyInputPanel
            value={typedValue}
            onUserInput={onUserInput}
            onMax={handleMax}
            showMaxButton={!atMaxAmount}
            currency={mockToken}
            pair={dummyPair}
            label={''}
            disableCurrencySelect={true}
            customBalanceText={t('earn.availableToDeposit')}
            id="stake-liquidity-token"
          />

          <HypotheticalRewardRate dim={!(nftSpots > 0)}>
            <div>
              <TYPE.black fontWeight={600}>NFT whitelist spots</TYPE.black>
            </div>

            <TYPE.black>
              {nftSpots}
            </TYPE.black>
          </HypotheticalRewardRate>

          <RowBetween>
            <ButtonConfirmed
              mr="0.5rem"
              onClick={onAttemptToApprove}
              confirmed={approval === ApprovalState.APPROVED || signatureData !== null}
              disabled={typedValue < minAmount || approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
            >
              {t('earn.approve')}
            </ButtonConfirmed>
            <ButtonError
              disabled={typedValue < minAmount || !!error || (signatureData === null && approval !== ApprovalState.APPROVED)}
              error={!!error && !!parsedAmount}
              onClick={onStake}
            >
              {depositText}
            </ButtonError>
          </RowBetween>
          <ProgressCircles steps={[approval === ApprovalState.APPROVED || signatureData !== null]} disabled={true} />
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Locking Tokens</TYPE.largeHeader>
            <TYPE.body fontSize={20}>{parsedAmount?.toSignificant(4)} Tokens</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>{t('earn.transactionSubmitted')}</TYPE.largeHeader>
            <TYPE.body fontSize={20}>{t('earn.deposited')} {parsedAmount?.toSignificant(4)} Tokens</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
