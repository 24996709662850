// @ts-nocheck
import { Currency, CAVAX, Token } from '@pangolindex/sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import PlebbitLogo from '../../assets/images/token-logo.png'
import AvaxLogo from '../../assets/images/avalanche_token_round.png'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'

import maticLogo from '../../assets/plebbit/matic.webp'
import xplebLogo from '../../assets/plebbit/xpleb.png'

// const getTokenLogoURL = (address: string) =>
//   `https://raw.githubusercontent.com/pangolindex/tokens/main/assets/${address}/logo.png`

const getTokenLogoURL = (address: string) => undefined

export const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (currency === CAVAX) return []

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.address)]
      }

      return [...uriLocations, getTokenLogoURL(currency.address)]
    }
    return []
  }, [currency, uriLocations])

  if (currency?.symbol?.match(/matic/i)) {
    return <StyledEthereumLogo src={maticLogo} size={size} style={style} />
  }

  if (currency?.symbol?.match(/xpleb/i)) {
    return <StyledEthereumLogo src={xplebLogo} size={size} style={style} />
  }

  if (currency === CAVAX) {
    return <StyledEthereumLogo src={AvaxLogo} size={size} style={style} />
  }

  // EDIT: hard code logo
  if (currency === CAVAX || currency?.symbol?.match(/avax/i)) {
    return <StyledEthereumLogo src={AvaxLogo} size={size} style={style} />
  }
  else {
    return <StyledEthereumLogo src={PlebbitLogo} size={size} style={style} />
  }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
