// @ts-nocheck
import React, { useState } from 'react'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonError } from '../Button'
import { DoubleSideStakingInfo, useMinichefPools } from '../../state/stake/hooks'
import { useStakingContract } from '../../hooks/useContract'
import { SubmittedView, LoadingView } from '../ModalViews'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import FormattedCurrencyAmount from '../FormattedCurrencyAmount'
import { useActiveWeb3React } from '../../hooks'
import { useTranslation } from 'react-i18next'
import prettyMilliseconds from 'pretty-ms'
import {useLockerContract} from '../../plebbit/hooks/nftLock'

import {useFarmContract} from '../../plebbit/hooks/farm'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: DoubleSideStakingInfo
  version: number
}

export default function UnstakingModal({ isOpen, onDismiss, stakingInfo, version }: StakingModalProps) {
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()

  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)

  function wrappedOnDismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }

  // const poolMap = useMinichefPools()
  const poolMap = new Map()
  // const stakingContract = useStakingContract(stakingInfo.stakingRewardAddress)
  // const stakingContract = useFarmContract()
  const stakingContract = useLockerContract(stakingInfo?.lockerAddress)


  async function onWithdraw() {
    if (stakingContract && poolMap && stakingInfo?.stakedAmount) {
      setAttempting(true)
      // const method = version < 2 ? 'exit' : 'withdrawAndHarvest'
      // const args = version < 2
      //   ? []
      //   : [poolMap[stakingInfo.stakedAmount.token.address], `0x${stakingInfo.stakedAmount?.raw.toString(16)}`, account]
      const method = 'unlock'
      const args = [stakingInfo?.lockIndex]

      // TODO: Support withdrawing partial amounts for v2+
      await stakingContract
        [method](...args)
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: 'Unlocked tokens'
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  let error: string | undefined
  if (!account) {
    error = t('earn.connectWallet')
  }
  if (!stakingInfo?.stakedAmount) {
    error = error ?? t('earn.enterAmount')
  }

  const timeLeft = stakingInfo?.timeLeft?.toNumber()
  let time = 'now'
  if (typeof timeLeft === 'number' && timeLeft !== 0) {
    time = 'in ' + prettyMilliseconds(timeLeft * 1000, {verbose: true, unitCount: 2})
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Withdraw</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          {stakingInfo?.stakedAmount && (
            <AutoColumn justify="center" gap="md">
              <TYPE.body fontWeight={600} fontSize={36}>
                {<FormattedCurrencyAmount currencyAmount={stakingInfo.stakedAmount} />}
              </TYPE.body>
              <TYPE.body>Unlock {time}.</TYPE.body>
            </AutoColumn>
          )}
          {/*stakingInfo?.earnedAmount && (
            <AutoColumn justify="center" gap="md">
              <TYPE.body fontWeight={600} fontSize={36}>
                {<FormattedCurrencyAmount currencyAmount={stakingInfo?.earnedAmount} />}
              </TYPE.body>
              <TYPE.body>{t('earn.unclaimedReward', { symbol: 'PLEB' })}</TYPE.body>
            </AutoColumn>
          )*/}
          {/*
          <TYPE.subHeader style={{ textAlign: 'center' }}>
            {t('earn.whenYouWithdrawWarning')}
          </TYPE.subHeader>
          */}
          <ButtonError disabled={!!error || timeLeft !== 0} error={!!error && !!stakingInfo?.stakedAmount} onClick={onWithdraw}>
            {error ?? 'Unlock'}
          </ButtonError>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.body fontSize={20}>
              Unlocking {stakingInfo?.stakedAmount?.toSignificant(4)} Tokens
            </TYPE.body>
            {/*
            <TYPE.body fontSize={20}>
              {t('earn.claimingReward', {
                amount: stakingInfo?.earnedAmount?.toSignificant(4),
                symbol: 'PLEB'
              })}
            </TYPE.body>
            */}
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>{t('earn.transactionSubmitted')}</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Unlocked Tokens</TYPE.body>
            {/* <TYPE.body fontSize={20}>{t('earn.claimedReward', { symbol: 'PLEB' })}</TYPE.body>*/}
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
