// the Pangolin Default token list lives here
// export const AEB_TOKENLIST = 'https://raw.githubusercontent.com/pangolindex/tokenlists/main/aeb.tokenlist.json'
// export const TOP_15_TOKEN_LIST = 'https://raw.githubusercontent.com/pangolindex/tokenlists/main/top15.tokenlist.json'
// export const DEFI_TOKEN_LIST = 'https://raw.githubusercontent.com/pangolindex/tokenlists/main/defi.tokenlist.json'
// export const STABLECOIN_TOKEN_LIST = 'https://raw.githubusercontent.com/pangolindex/tokenlists/main/stablecoin.tokenlist.json'
// export const AVAX_BRIDGE_LIST = 'https://raw.githubusercontent.com/pangolindex/tokenlists/main/ab.tokenlist.json'

export const AEB_TOKENLIST = ''
export const TOP_15_TOKEN_LIST = ''
export const DEFI_TOKEN_LIST = ''
export const STABLECOIN_TOKEN_LIST = ''
export const AVAX_BRIDGE_LIST = ''

export const DEFAULT_TOKEN_LISTS_SELECTED: string[] = [AVAX_BRIDGE_LIST, DEFI_TOKEN_LIST]

export const DEFAULT_TOKEN_LISTS: string[] = [
  TOP_15_TOKEN_LIST,
  AEB_TOKENLIST,
  DEFI_TOKEN_LIST,
  STABLECOIN_TOKEN_LIST,
  AVAX_BRIDGE_LIST
]
